import { Currency, Token } from '@pancakeswap/sdk'
import { Button, Text, Modal, ModalBody, useModal, InjectedModalProps, Link } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { AutoRow } from 'components/Layout/Row'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getBlockExploreLink } from 'utils'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useUnsupportedTokens } from '../hooks/Tokens'

interface Props extends InjectedModalProps {
  currencies: (Currency | undefined)[]
}

const DetailsFooter = styled.div`
  padding: 8px 0;
  width: 100%;
  max-width: 400px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.invertedContrast};
  text-align: center;
`

const UnsupportedModal: React.FC<React.PropsWithChildren<Props>> = ({ currencies, onDismiss }) => {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const tokens =
    chainId && currencies
      ? currencies.map((currency) => {
          return wrappedCurrency(currency, chainId)
        })
      : []

  const unsupportedTokens: { [address: string]: Token } = useUnsupportedTokens()

  return (
    <Modal title={t('What happened with Tyson?')} maxWidth="420px" onDismiss={onDismiss}>
      <ModalBody maxWidth="420px">
      <AutoColumn gap="lg">
        {tokens.map((token) => {
          return (
            token &&
            unsupportedTokens &&
            Object.keys(unsupportedTokens).includes(token.address) && (
              <AutoColumn key={token.address?.concat('not-supported')} gap="10px">
                <AutoRow gap="5px" align="center">
                  <CurrencyLogo currency={token} size="24px" />
                  <Text>Name:</Text>
                  <Text style={{textShadow: "1px 1px 1px rgba(0, 0, 0, 1)"}} color="orange">{token.name}</Text>
                  <Text>Symbol:</Text>
                  <Text style={{textShadow: "1px 1px 1px rgba(0, 0, 0, 1)"}} color="orange">{token.symbol}</Text>
                </AutoRow>
                {chainId && (<>
                <Text paddingTop="12px">Old Contract:
                  </Text>
                  <Link
                    external
                    small
                    color="lime"
                    paddingTop="0px"
                    className=""
                    href={getBlockExploreLink(token.address, 'address', chainId)}
                  >
                    {token.address}
                  </Link></>
                )}
              </AutoColumn>
            )
          )
        })}
        <AutoColumn gap="lg" style={{justifyContent: "center", justifySelf: "center"}}>
          <Text>
            {/* t(
              'Some assets are not available through this interface because their liquidity has been moved. Check project or ChewySwap Telegram Group for More Information',
            ) */}
            There was a problem with the old Tyson token contract and the creator reached out to us for help. A new contract was deployed and holders were airdropped.

          <Button as={Link} style={{textDecoration: "none", justifySelf: "center", marginTop: "26px", marginBottom: "25px"}} textAlign="center" external={false} href='../../swap/?chain=shibarium&outputCurrency=0x8eD7A837a62759Ea22F5fAbEFf33227aEaAF65f4' width="full">Trade new Tyson Token Contract</Button>
          </Text>
        </AutoColumn>
      </AutoColumn>
      </ModalBody>
    </Modal>
  )
}

export default function UnsupportedCurrencyFooter({ currencies }: { currencies: (Currency | undefined)[] }) {
  const { t } = useTranslation()
  const [onPresentModal] = useModal(<UnsupportedModal currencies={currencies} />)

  return (
    <DetailsFooter>
      <Button variant="text" onClick={onPresentModal}>
        {t('Tyson has migrated, click here for more info')}
      </Button>
    </DetailsFooter>
  )
}
